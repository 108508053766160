<template>
  <div class="app-event-list">
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="transactions"
          :button-label="''"
          :columns="transactionColumns"
          :method-type="'PUT'"
          :total-column="'response.entries'"
          :data-column="'response.data'"
          :api-endpoint="'/api/UserTransactions/ListAsync'"
          :extra-params="extraParams"
          :export-columns="transactionColumns"
          :export-api-endpoint="'/api/UserTransactions/ListAsync'"
          :export-local="true"
          @export-excel="downloadTransactions"
          @add-new-info="$helpers.goTo(that, 'group-event-add')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { appDeleteTransaction } from '@/@core/services/groups'
import {
  hideLoader,
  showDangerNotification,
  showLoader,
  showSuccessNotification,
} from '@/@core/comp-functions/ui/app'
import Constants from '@/constants'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import axios from 'axios'

export default {
  components: {
    GoodDataTable,
    BRow,
    BCol,
  },
  data() {
    return {
      that: this,
      transactionColumns: [
        {
          label: 'Entity 1',
          field: 'entity1',
          useRenderer: true,
          renderer: props => {
            if (props.entity1Detail) {
              if (props.entity1Detail.name) {
                return props.entity1Detail.name
              }
              return [props.entity1Detail.firstName, props.entity1Detail.surname].filter(x => x).join(' ')
            }
            return ''
          },
        },
        {
          label: 'Entity 2',
          field: 'entity2',
          useRenderer: true,
          renderer: props => {
            if (props.entity2Detail) {
              if (props.entity2Detail.name) {
                return props.entity2Detail.name
              }
              return [props.entity2Detail.firstName, props.entity2Detail.surname].filter(x => x).join(' ')
            }
            return ''
          },
        },
        {
          label: 'Amount',
          field: 'amount',
        },
        {
          label: 'Transaction Type',
          field: 'transactionType',
          useRenderer: true,
          renderer: props => Constants.Constants.UserTransactionReverseMap[props.transactionType],
        },
        {
          label: 'Ledger Type',
          field: 'ledgerType',
          useRenderer: true,
          renderer: props => Constants.Constants.LedgerTypes[props.ledgerType],
        },
        {
          label: 'Created By',
          field: 'createdBy',
          useRenderer: true,
          renderer: props => {
            if (props.createdByDetail) {
              return [props.createdByDetail.firstName, props.createdByDetail.surname].filter(x => x).join(' ')
            }
            return ''
          },
        },
        {
          label: 'Created Time',
          field: 'creationDate',
          useRenderer: true,
          renderer: props => this.$helpers.formatDate(props.creationDate),
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.deleteTransaction(props.id)
              },
            },
          ].filter(x => (window.ManMudra.getters.isSuperAdmin() ? x : x.text !== 'Edit')),
        },
      ],
    }
  },
  computed: {
    unionSelected() {
      return this.$store.getters['groups/currentUnion']
    },
    extraParams() {
      if (!this.unionSelected) return ''
      return `unionId=${this.unionSelected.id}`
    },
  },
  watch: {
    unionSelected: {
      immediate: true,
      handler() {
        setTimeout(() => {
          this.runInitial()
        }, 150)
      },
    },
  },
  methods: {
    runInitial() {
      if (this.$refs.transactions) {
        this.$refs.transactions.loadItems()
      }
    },
    deleteTransaction(transactionId) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Do you really want to delete?')) {
        appDeleteTransaction({ id: transactionId }).then(({ data }) => {
          if (data.succeeded) {
            showSuccessNotification(this, 'Transaction deleted')
            this.$refs.transactions.loadItems()
          } else {
            showDangerNotification(this, data.message)
          }
        })
      }
    },
    async downloadTransactions($event) {
      const userFilter = {}
      userFilter.from = 0
      userFilter.unionId = this.unionSelected.id
      // userFilter.count = $event.entries
      userFilter.count = 1000
      userFilter.textToSearch = $event.textToSearch
      userFilter.isAdminExport = true
      showLoader()
      try {
        const response = await axios.post(
          `${jwtDefaultConfig.groupsApi}/api/UserTransactions/ExportTransactionExcel`,
          userFilter,
          {
            responseType: 'blob', // Important: set responseType to 'blob' to handle binary data
          },
        )
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'UserTransactions.xlsx')
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
        window.URL.revokeObjectURL(url)
        hideLoader()
      } catch (error) {
        showDangerNotification(this, 'Failed to export transactions')
        hideLoader()
      }
    },
  },
}
</script>
