const UserTransactionReverseMap = {
  1: 'Fees',
  2: 'Cash in Hand',
  3: 'Donation',
  4: 'Expense',
  5: 'Grant',
  6: 'Deposit',
  7: 'Withdraw',
  8: 'Transfer',
}

const LedgerTypes = {
  0: 'Heading',
  1: 'Income',
  2: 'Expense',
  3: 'Liability',
  4: 'Asset',
  5: 'Transfer',
  6: 'Bank',
}

const UserTransactionStatus = {
  1: 'Pending',
  2: 'Accepted',
  3: 'Rejected',
}

export default {
  Constants: {
    LedgerTypes,
    UserTransactionStatus,
    UserTransactionReverseMap,
  },
}
